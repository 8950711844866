import { Amplify, Auth, Hub } from 'aws-amplify';
import { useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import awsConfig from '../aws-exports';
import './App.css';
import Navbar from './navbar/Navbar';
import { Home } from './routes/Home';
import Login from './routes/Login/Login';
import { Status } from './routes/Page1';
import { Page2 } from './routes/Page2';
import { Page3 } from './routes/Page3';
import { Page404 } from './routes/Page404';

const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/),
);

const isStaging = window.location.hostname.includes('staging'); //hacky as hell, to be replaced later once we confirm this works
const isDev = window.location.hostname.includes('dev') && !isStaging;

// Assuming you have two redirect URIs, and the first is for localhost and second is for production
const [localRedirectSignIn, devRedirectSignIn, stagingRedirectSignIn, productionRedirectSignIn] =
  awsConfig.oauth.redirectSignIn.split(',');

const [localRedirectSignOut, devRedirectSignOut, stagingRedirectSignOut, productionRedirectSignOut] =
  awsConfig.oauth.redirectSignOut.split(',');

const redirectSignIn = isLocalhost
  ? localRedirectSignIn
  : isDev
  ? devRedirectSignIn
  : isStaging
  ? stagingRedirectSignIn
  : productionRedirectSignIn;
const redirectSignOut = isLocalhost
  ? localRedirectSignOut
  : isDev
  ? devRedirectSignOut
  : isStaging
  ? stagingRedirectSignOut
  : productionRedirectSignOut;

const updatedAwsConfig = {
  ...awsConfig,
  oauth: {
    ...awsConfig.oauth,
    redirectSignIn,
    redirectSignOut,
  },
};

Amplify.configure(updatedAwsConfig);

function App(props?: { user?: string; signOut?: () => void }) {
  const [user, setUser] = useState(null);
  const [customState, setCustomState] = useState(null);

  useEffect(() => {
    const unsubscribe = Hub.listen('auth', ({ payload: { event, data } }) => {
      switch (event) {
        case 'signIn':
          setUser(data);
          break;
        case 'signOut':
          setUser(null);
          break;
        case 'customOAuthState':
          setCustomState(data);
      }
    });

    Auth.currentAuthenticatedUser()
      .then((currentUser) => setUser(currentUser))
      .catch(() => console.log('Not signed in'));

    return unsubscribe;
  }, []);

  if (!user) {
    return <Login />;
  }

  return (
    <div className="App">
      <>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/status" element={<Status />} />
          <Route path="/page-2" element={<Page2 />} />
          <Route path="/page-3" element={<Page3 />} />
          <Route path="*" element={<Page404 />} />
        </Routes>
      </>
    </div>
  );
}

export default App;
