import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import LoginWithGoogle from '../../components/google/LoginWithGoogle';

import './Login.css';

function Login() {
  return (
    <div className="Login">
      <Box
        sx={{
          width: '300px',
          height: '200px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'space-evenly',
        }}
      >
        <Typography
          variant="h6"
          noWrap
          component="a"
          href="/"
          sx={{
            mr: 2,
            display: { xs: 'none', md: 'flex' },
            fontFamily: 'monospace',
            color: 'white',
            fontWeight: 400,
            textDecoration: 'none',
          }}
        >
          Welcome to Pidzorna!
        </Typography>
        <LoginWithGoogle />
      </Box>
    </div>
  );
}

export default Login;
