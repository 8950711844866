import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import { Auth } from 'aws-amplify';

import './LoginWithGoogle.css';

function LoginWithGoogle() {
  return (
    <button
      className="login-with-google-btn"
      onClick={() => Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Google })}
    >
      Sign in with Google
    </button>
  );
}

export default LoginWithGoogle;
