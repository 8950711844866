import LogoutIcon from '@mui/icons-material/LogoutRounded';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { Auth } from 'aws-amplify';
import * as React from 'react';
import { Link } from 'react-router-dom';

const pages = [
  { name: 'Status', url: 'status' },
  { name: 'Page 2', url: 'page-2' },
  { name: 'Page 3', url: 'page-3' },
];

const Navbar = () => {
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <AppBar position="static">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <RocketLaunchIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} />
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
              fontFamily: 'monospace',
              fontWeight: 400,
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            Pidzorna
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {pages.map((page) => (
              <Link
                key={page.url}
                onClick={handleCloseNavMenu}
                to={page.url}
                style={{ color: 'white', display: 'block', width: '75px', textDecoration: 'none' }}
              >
                <Typography
                  variant="h6"
                  noWrap
                  component="a"
                  sx={{
                    my: 2,
                    fontFamily: 'Roboto',
                    fontWeight: 400,
                    textDecoration: 'none',
                  }}
                >
                  {page.name}
                </Typography>
              </Link>
            ))}
          </Box>
          <Box
            sx={{ cursor: 'pointer', display: 'flex', width: '150px', alignItems: 'center', justifyContent: 'center' }}
            onClick={() => Auth.signOut()}
          >
            <LogoutIcon sx={{ mr: 1, ml: 3 }} />

            <Typography
              variant="h6"
              noWrap
              component="a"
              sx={{
                fontFamily: 'Roboto',
                fontWeight: 400,
                textDecoration: 'none',
              }}
            >
              Logout
            </Typography>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default Navbar;
